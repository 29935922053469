@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400&display=swap');

* {
  font-family: 'Rubik', sans-serif;
}

i {
  font-family: unset;
}

.roomConnectorPageWrapper {
  position: relative;
  background-image: url("https://ak.picdn.net/shutterstock/videos/6646445/thumb/1.jpg");
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .gradientLayer {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    // background: linear-gradient(-45deg, #dd9f8c, #d383a2, #7fbbd1, #7bd6c1);
    background-size: 400% 400%;
    animation: gradient 10s ease infinite;
    opacity: 0.9;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  .roomConnetor {
    width: 350px;
    min-height: 450px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 2px 2px 28px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    z-index: 1111;

    h3 {
      font-weight: 400;
      font-size: 1.5rem;
      text-decoration: underline;
    }

    .tabButtons {
      .tabBtn {
        margin-top: 4rem;
        outline: none;
        background-color: white;
        border: none;
        cursor: pointer;
        font-size: 0.8rem;
        padding: 0 20px 6px 20px;

        &.active {
          border-bottom: 2px solid black;
        }
      }
    }

    .tabContent {
      padding: 2rem 0;
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;

      .inputWrapper {
        width: 100%;
        margin-bottom: 1.5rem;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 1px solid black;
        ;

        .label {
          flex: 4;
          height: 100%;
          text-align: left;
          font-size: 0.6rem;
          color: white;
          background-color: black;
          padding: 6px 15px;
          height: 11px;
        }

        input, select {
          height: 23px;
          padding: 0 7px;
          flex: 9;
          outline: none;
          border: none;
          font-size: 0.7rem;
          font-weight: 600;
        }
      }

      .submitBtn {

        background-color: rgb(0, 68, 228);
        border: none;
        border-radius: 3px;
        box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.2);
        padding: 6px 0;
        width: 150px;
        font-size: 0.7rem;
        cursor: pointer;
        color: white;
        margin-top: 1rem;
      }

      .progressViewer {
        background-color: rgba(0, 68, 228, 0.445);
        border: none;
        border-radius: 3px;
        box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.2);
        padding: 6px 0;
        text-align: center;
        width: 150px;
        font-size: 0.7rem;
        color: white;
        margin-top: 1rem;
      }

      .errorText {
        font-size: 0.6rem;
        margin-top: 20px;
        color: red;

        font-weight: 600;
      }
    }
  }
}



@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}