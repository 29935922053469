.localUserVideoWindow {
  $medium: 769px;
  $small: 360px;
  .videoOptions {
   
  }
  .name {
    background-color: white!important;
    display: flex;
    align-items: center;
    padding-left: 15px!important;
    .toggleButton {
      border-radius: 25px;
      width: 25px;
      height: 25px;
      border: none;
      background-color: rgb(212, 212, 212);
      cursor: pointer;
      color: white;
      display: flex;
      font-size: 0.7rem!important;
      justify-content: center;
      align-items: center;
      padding: 0 0 0 2px !important;
      margin-right: 9px;
      &.active {
        background-color: rgb(0, 184, 0);
      }
    }
        @media screen and (max-width: $medium) {
          padding: 0 0 0 7px!important;
          width: 97%!important;
          border-radius: 0!important;
          margin: 0!important;
          background-color: rgb(15, 15, 15)!important;
          height: 23px;
          color: white!important;
          .toggleButton {
            border-radius: 15px;
            width: 15px;
            height: 15px;
          }
        }
  }
}