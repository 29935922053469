.questionMeta {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.376);
  padding: 15px;
  width: 110%;
  margin-left: -2rem;
  flex-direction: column;
  
  --line-border-fill: black;
  --line-border-empty: #e0e0e0;
  h4 {
    text-align: center;
    color: black;
  }

.progress-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 30px;
  max-width: 100%;
  width: 100%;
}

.progress-container::before {
  content: '';
  background-color: var(--line-border-empty);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  width: 100%;
  z-index: 1;
}

.progress {
  background-color: var(--line-border-fill);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 4px;
  width: 25%;
  z-index: 2;
  transition: 0.4s ease;
}

.circle {
  background-color: #fff;
  color: #999;
  border-radius: 50%;
  height: 20px;
  font-size: 0.7rem;
  width: 20px;
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  border: 3px solid var(--line-border-empty);
  transition: 0.4s ease;
  z-index: 3;
}

.circle.active {
  border-color: var(--line-border-fill);
  background-color: var(--line-border-fill);
  color: white;
}
}

.nextQuestionAlert {
  margin-top: 3rem;
  width: 100%;
  display: flex;
  height: 20px;
justify-content: center;
align-items: center;
.progressbar {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
}

.CircularProgressbar-path {
  stroke: black !important;
  border: 15px;
}
.CircularProgressbar-trail {
  stroke: rgb(250, 250, 250);
}

.questionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .sessionCompleteImg {
    width: 80%;
    margin: 0 10%;
  }
  
  .timer-wrapper {
    display: flex;
    justify-content: center;
  }
  
  .timer {
    font-family: "Montserrat";
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .text {
    color: #aaa;
  }
  
  .value {
    font-size: 40px;
  }
}

.startBtnWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    // width: 100%;
    padding: 6px 55px;
    font-size: 0.7rem;
    font-weight: 600;
    color: white;
    background-color: rgb(16, 245, 0);
    border: 1px solid rgba(0, 128, 0, 0);
    border-radius: 5px;
    box-shadow: 5px 5px 25px rgba(0,0,0,0.12);
    cursor: pointer;
    &:hover {
      background-color: rgb(15, 218, 1);
      box-shadow: 5px 5px 25px rgba(0, 0, 0, 0);
    }
  }
  margin-bottom: 25px;
}

.countdownWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
