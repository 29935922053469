@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.gameWRapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  font-family: 'Titillium Web', sans-serif;
  overflow-x: hidden;
  overflow-y: hidden; }
  @media screen and (max-width: 769px) {
    .gameWRapper {
      flex-direction: column; } }
  .gameWRapper .videoWindowWrapper {
    flex: 7 1;
    height: 100%;
    z-index: 99999; }
    @media screen and (max-width: 769px) {
      .gameWRapper .videoWindowWrapper {
        flex: 1 1;
        height: 40vh; } }
    .gameWRapper .videoWindowWrapper .videoWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      height: 100%;
      width: 100%; }
      .gameWRapper .videoWindowWrapper .videoWrapper .video {
        width: 50%; }
        @media screen and (max-width: 769px) {
          .gameWRapper .videoWindowWrapper .videoWrapper .video {
            height: 200px; } }
        .gameWRapper .videoWindowWrapper .videoWrapper .video .videoWindow {
          width: 100%;
          height: 100%;
          padding: 1px; }
          .gameWRapper .videoWindowWrapper .videoWrapper .video .videoWindow .videoPlaceholder {
            width: 100%;
            height: 100%;
            position: relative; }
            .gameWRapper .videoWindowWrapper .videoWrapper .video .videoWindow .videoPlaceholder .answer {
              position: absolute;
              top: 0;
              left: 0;
              background-color: #2aedff;
              color: #181818;
              width: 95%;
              padding: 15px;
              font-size: 1rem;
              text-align: center; }
            .gameWRapper .videoWindowWrapper .videoWrapper .video .videoWindow .videoPlaceholder .video {
              width: 100%;
              height: 100%; }
            .gameWRapper .videoWindowWrapper .videoWrapper .video .videoWindow .videoPlaceholder video {
              width: 100%;
              height: 100%;
              object-fit: cover; }
            .gameWRapper .videoWindowWrapper .videoWrapper .video .videoWindow .videoPlaceholder .videoMask {
              position: absolute;
              display: none;
              top: 0;
              right: 0;
              width: 100%;
              height: 100%;
              cursor: pointer; }
            .gameWRapper .videoWindowWrapper .videoWrapper .video .videoWindow .videoPlaceholder .videoOptions {
              position: absolute;
              bottom: 0;
              left: 0;
              height: 23px;
              width: 100%; }
              @media screen and (max-width: 769px) {
                .gameWRapper .videoWindowWrapper .videoWrapper .video .videoWindow .videoPlaceholder .videoOptions {
                  height: 23px; } }
              .gameWRapper .videoWindowWrapper .videoWrapper .video .videoWindow .videoPlaceholder .videoOptions .name {
                background-color: rgba(255, 255, 255, 0.637);
                width: 30%;
                border-radius: 600px;
                padding: 7px 0;
                margin: 7px 15px;
                text-align: center;
                font-size: 0.8rem;
                color: #1b1b1b;
                text-shadow: 0 0 2px #161616, 0 0 2px rgbrgb(12, 12, 12);
                padding: 0 0 0 7px !important;
                width: 98% !important;
                border-radius: 0 !important;
                margin: 0 !important;
                background-color: #0f0f0f !important;
                height: 23px;
                color: white !important; }
                @media screen and (max-width: 769px) {
                  .gameWRapper .videoWindowWrapper .videoWrapper .video .videoWindow .videoPlaceholder .videoOptions .name {
                    width: 85%; } }
                .gameWRapper .videoWindowWrapper .videoWrapper .video .videoWindow .videoPlaceholder .videoOptions .name .toggleButton {
                  border-radius: 15px;
                  width: 15px;
                  height: 15px; }
            .gameWRapper .videoWindowWrapper .videoWrapper .video .videoWindow .videoPlaceholder:hover .videoMask {
              display: unset;
              box-shadow: 0px 0px 200px rgba(26, 26, 26, 0.808) inset; }
            .gameWRapper .videoWindowWrapper .videoWrapper .video .videoWindow .videoPlaceholder:hover .name {
              background-color: rgba(255, 255, 255, 0.986); }
  .gameWRapper .questionSectionWrapper {
    flex: 3 1;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden; }
    @media screen and (max-width: 769px) {
      .gameWRapper .questionSectionWrapper {
        flex: 1 1;
        padding: 185px 25px 55px 25px;
        overflow-y: scroll; } }
    .gameWRapper .questionSectionWrapper .questionWrapper {
      width: 60%; }
      @media screen and (max-width: 769px) {
        .gameWRapper .questionSectionWrapper .questionWrapper {
          width: 90%; } }
      .gameWRapper .questionSectionWrapper .questionWrapper .roomJoinerWrapper {
        background-color: rgba(241, 241, 241, 0.945);
        padding: 1rem;
        width: 130%;
        margin-left: 0rem; }
        @media screen and (max-width: 769px) {
          .gameWRapper .questionSectionWrapper .questionWrapper .roomJoinerWrapper {
            margin-left: -1rem;
            width: 100%; } }
        .gameWRapper .questionSectionWrapper .questionWrapper .roomJoinerWrapper p {
          font-weight: 400;
          font-size: 0.9rem; }
        .gameWRapper .questionSectionWrapper .questionWrapper .roomJoinerWrapper .joiningWrapper {
          margin-top: 2rem;
          border-radius: 3px;
          width: 100%;
          color: white;
          text-align: center;
          background-color: black; }
          .gameWRapper .questionSectionWrapper .questionWrapper .roomJoinerWrapper .joiningWrapper .label {
            font-size: 0.9rem;
            padding: 4px; }
          .gameWRapper .questionSectionWrapper .questionWrapper .roomJoinerWrapper .joiningWrapper .value {
            background-color: #474747;
            border-radius: 3px;
            font-size: 0.8rem;
            font-weight: 600;
            padding: 12px 35px;
            position: relative; }
            .gameWRapper .questionSectionWrapper .questionWrapper .roomJoinerWrapper .joiningWrapper .value .copy {
              background-color: rgba(209, 149, 149, 0);
              color: white;
              border: none;
              outline: none;
              position: absolute;
              right: 15px;
              cursor: pointer; }
      .gameWRapper .questionSectionWrapper .questionWrapper h3 {
        font-weight: 600;
        text-align: center; }
      .gameWRapper .questionSectionWrapper .questionWrapper p {
        text-align: center; }
        .gameWRapper .questionSectionWrapper .questionWrapper p strong {
          font-size: 1rem; }
      .gameWRapper .questionSectionWrapper .questionWrapper .answers {
        display: flex;
        justify-content: center;
        margin-top: 3rem;
        align-items: center;
        flex-direction: column; }
        .gameWRapper .questionSectionWrapper .questionWrapper .answers .answer {
          padding: 8px 16px;
          color: white;
          transition: 0.4s;
          width: 95%;
          border: 1px solid black;
          text-align: center;
          background-color: #1b1b1b;
          border-radius: 30px;
          font-size: 0.8rem;
          margin: 3px 0;
          cursor: pointer; }
          .gameWRapper .questionSectionWrapper .questionWrapper .answers .answer.selected {
            border: 1px solid #e4e1e1;
            text-align: center;
            background-color: #00cc3d;
            color: #2a2a2a; }
        @media screen and (max-width: 769px) {
          .gameWRapper .questionSectionWrapper .questionWrapper .answers {
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 0rem; }
            .gameWRapper .questionSectionWrapper .questionWrapper .answers .answer {
              margin: 3px 2px;
              width: 48%; } }


.localUserVideoWindow .name {
  background-color: white !important;
  display: flex;
  align-items: center;
  padding-left: 15px !important; }
  .localUserVideoWindow .name .toggleButton {
    border-radius: 25px;
    width: 25px;
    height: 25px;
    border: none;
    background-color: #d4d4d4;
    cursor: pointer;
    color: white;
    display: flex;
    font-size: 0.7rem !important;
    justify-content: center;
    align-items: center;
    padding: 0 0 0 2px !important;
    margin-right: 9px; }
    .localUserVideoWindow .name .toggleButton.active {
      background-color: #00b800; }
  @media screen and (max-width: 769px) {
    .localUserVideoWindow .name {
      padding: 0 0 0 7px !important;
      width: 97% !important;
      border-radius: 0 !important;
      margin: 0 !important;
      background-color: #0f0f0f !important;
      height: 23px;
      color: white !important; }
      .localUserVideoWindow .name .toggleButton {
        border-radius: 15px;
        width: 15px;
        height: 15px; } }



.questionMeta {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.376);
  padding: 15px;
  width: 110%;
  margin-left: -2rem;
  flex-direction: column;
  --line-border-fill: black;
  --line-border-empty: #e0e0e0; }
  .questionMeta h4 {
    text-align: center;
    color: black; }
  .questionMeta .progress-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 30px;
    max-width: 100%;
    width: 100%; }
  .questionMeta .progress-container::before {
    content: '';
    background-color: var(--line-border-empty);
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: 4px;
    width: 100%;
    z-index: 1; }
  .questionMeta .progress {
    background-color: var(--line-border-fill);
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    height: 4px;
    width: 25%;
    z-index: 2;
    transition: 0.4s ease; }
  .questionMeta .circle {
    background-color: #fff;
    color: #999;
    border-radius: 50%;
    height: 20px;
    font-size: 0.7rem;
    width: 20px;
    display: flex;
    align-items: center;
    font-weight: 600;
    justify-content: center;
    border: 3px solid var(--line-border-empty);
    transition: 0.4s ease;
    z-index: 3; }
  .questionMeta .circle.active {
    border-color: var(--line-border-fill);
    background-color: var(--line-border-fill);
    color: white; }

.nextQuestionAlert {
  margin-top: 3rem;
  width: 100%;
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center; }
  .nextQuestionAlert .progressbar {
    width: 20px;
    height: 20px;
    margin-right: 10px; }

.CircularProgressbar-path {
  stroke: black !important;
  border: 15px; }

.CircularProgressbar-trail {
  stroke: #fafafa; }

.questionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .questionWrapper .sessionCompleteImg {
    width: 80%;
    margin: 0 10%; }
  .questionWrapper .timer-wrapper {
    display: flex;
    justify-content: center; }
  .questionWrapper .timer {
    font-family: "Montserrat";
    display: flex;
    flex-direction: column;
    align-items: center; }
  .questionWrapper .text {
    color: #aaa; }
  .questionWrapper .value {
    font-size: 40px; }

.startBtnWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px; }
  .startBtnWrapper button {
    padding: 6px 55px;
    font-size: 0.7rem;
    font-weight: 600;
    color: white;
    background-color: #10f500;
    border: 1px solid rgba(0, 128, 0, 0);
    border-radius: 5px;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.12);
    cursor: pointer; }
    .startBtnWrapper button:hover {
      background-color: #0fda01;
      box-shadow: 5px 5px 25px rgba(0, 0, 0, 0); }

.countdownWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; }

* {
  font-family: 'Rubik', sans-serif; }

i {
  font-family: unset; }

.roomConnectorPageWrapper {
  position: relative;
  background-image: url("https://ak.picdn.net/shutterstock/videos/6646445/thumb/1.jpg");
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }
  .roomConnectorPageWrapper .gradientLayer {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    -webkit-animation: gradient 10s ease infinite;
            animation: gradient 10s ease infinite;
    opacity: 0.9;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; }
  .roomConnectorPageWrapper .roomConnetor {
    width: 350px;
    min-height: 450px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 2px 2px 28px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    z-index: 1111; }
    .roomConnectorPageWrapper .roomConnetor h3 {
      font-weight: 400;
      font-size: 1.5rem;
      text-decoration: underline; }
    .roomConnectorPageWrapper .roomConnetor .tabButtons .tabBtn {
      margin-top: 4rem;
      outline: none;
      background-color: white;
      border: none;
      cursor: pointer;
      font-size: 0.8rem;
      padding: 0 20px 6px 20px; }
      .roomConnectorPageWrapper .roomConnetor .tabButtons .tabBtn.active {
        border-bottom: 2px solid black; }
    .roomConnectorPageWrapper .roomConnetor .tabContent {
      padding: 2rem 0;
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column; }
      .roomConnectorPageWrapper .roomConnetor .tabContent .inputWrapper {
        width: 100%;
        margin-bottom: 1.5rem;
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 1px solid black; }
        .roomConnectorPageWrapper .roomConnetor .tabContent .inputWrapper .label {
          flex: 4 1;
          height: 100%;
          text-align: left;
          font-size: 0.6rem;
          color: white;
          background-color: black;
          padding: 6px 15px;
          height: 11px; }
        .roomConnectorPageWrapper .roomConnetor .tabContent .inputWrapper input, .roomConnectorPageWrapper .roomConnetor .tabContent .inputWrapper select {
          height: 23px;
          padding: 0 7px;
          flex: 9 1;
          outline: none;
          border: none;
          font-size: 0.7rem;
          font-weight: 600; }
      .roomConnectorPageWrapper .roomConnetor .tabContent .submitBtn {
        background-color: #0044e4;
        border: none;
        border-radius: 3px;
        box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.2);
        padding: 6px 0;
        width: 150px;
        font-size: 0.7rem;
        cursor: pointer;
        color: white;
        margin-top: 1rem; }
      .roomConnectorPageWrapper .roomConnetor .tabContent .progressViewer {
        background-color: rgba(0, 68, 228, 0.445);
        border: none;
        border-radius: 3px;
        box-shadow: 1px 1px 30px rgba(0, 0, 0, 0.2);
        padding: 6px 0;
        text-align: center;
        width: 150px;
        font-size: 0.7rem;
        color: white;
        margin-top: 1rem; }
      .roomConnectorPageWrapper .roomConnetor .tabContent .errorText {
        font-size: 0.6rem;
        margin-top: 20px;
        color: red;
        font-weight: 600; }

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

@keyframes gradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }

