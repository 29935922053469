.gameWRapper {
  $medium: 769px;
  $small: 360px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  font-family: 'Titillium Web', sans-serif;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (max-width: $medium) {
    flex-direction: column;
  }

  .videoWindowWrapper {
    flex: 7;
    height: 100%;
    z-index: 99999;

    @media screen and (max-width: $medium) {
      flex: 1;
      height: 40vh;
    }

    .videoWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      height: 100%;
      width: 100%;

      .video {
        // flex: 1;
        width: 50%;

        @media screen and (max-width: $medium) {
          height: 200px;
        }

        // background-color: white;

        .videoWindow {
          width: 100%;
          height: 100%;
          padding: 1px;

          .videoPlaceholder {
            width: 100%;
            height: 100%;
            position: relative;

            .answer {
              position: absolute;
              top: 0;
              left: 0;
              background-color: rgb(42, 237, 255);
              color: rgb(24, 24, 24);
              width: 95%;
              padding: 15px;
              font-size: 1rem;
              text-align: center;
            }

            .video {
              width: 100%;
              height: 100%;
            }

            video {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            .videoMask {
              position: absolute;
              display: none;
              top: 0;
              right: 0;
              width: 100%;
              height: 100%;
              cursor: pointer;

            }

            .videoOptions {
              position: absolute;
              bottom: 0;
              left: 0;
              height: 23px;
              width: 100%;

              @media screen and (max-width: $medium) {
                height: 23px;
              }

              .name {
                background-color: rgba(255, 255, 255, 0.637);
                width: 30%;

                @media screen and (max-width: $medium) {
                  width: 85%;
                }

                border-radius: 600px;
                padding: 7px 0;
                margin: 7px 15px;
                text-align: center;
                font-size: 0.8rem;
                color: rgb(27, 27, 27);
                text-shadow: 0 0 2px rgb(22, 22, 22),
                0 0 2px rgbrgb(12, 12, 12);

                padding: 0 0 0 7px !important;
                width: 98% !important;
                border-radius: 0 !important;
                margin: 0 !important;
                background-color: rgb(15, 15, 15) !important;
                height: 23px;
                color: white !important;

                .toggleButton {
                  border-radius: 15px;
                  width: 15px;
                  height: 15px;
                }

              }
            }

            &:hover {
              .videoMask {
                display: unset;
                box-shadow: 0px 0px 200px rgba(26, 26, 26, 0.808) inset;
              }

              .name {
                background-color: rgba(255, 255, 255, 0.986);
              }
            }
          }
        }
      }
    }
  }

  .questionSectionWrapper {
    flex: 3;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;

    @media screen and (max-width: $medium) {
      // height: 60vh;
      flex: 1;
      padding: 185px 25px 55px 25px;
      overflow-y: scroll;
    }

    .questionWrapper {
      width: 60%;

      @media screen and (max-width: $medium) {
        width: 90%;
      }

      .roomJoinerWrapper {
        background-color: rgba(241, 241, 241, 0.945);
        padding: 1rem;
        width: 130%;
        margin-left: 0rem;

        @media screen and (max-width: $medium) {
          margin-left: -1rem;
          width: 100%;
        }

        p {
          font-weight: 400;
          font-size: 0.9rem;
        }

        .joiningWrapper {
          margin-top: 2rem;
          border-radius: 3px;
          width: 100%;
          color: white;
          text-align: center;
          background-color: black;

          .label {
            font-size: 0.9rem;
            padding: 4px;
            // font-weight: 600;
          }

          .value {
            background-color: rgb(71, 71, 71);
            border-radius: 3px;
            font-size: 0.8rem;
            font-weight: 600;
            padding: 12px 35px;
            position: relative;

            .copy {
              background-color: rgba(209, 149, 149, 0);
              color: white;
              border: none;
              outline: none;
              position: absolute;
              right: 15px;
              cursor: pointer;
            }
          }
        }
      }

      h3 {
        font-weight: 600;
        text-align: center;
      }

      p {
        text-align: center;

        strong {
          font-size: 1rem;
        }
      }

      .answers {
        display: flex;
        justify-content: center;
        margin-top: 3rem;
        align-items: center;
        flex-direction: column;


        .answer {
          padding: 8px 16px;
          color: white;
          transition: 0.4s;
          width: 95%;
          border: 1px solid black;
          text-align: center;
          background-color: rgb(27, 27, 27);
          border-radius: 30px;
          font-size: 0.8rem;
          margin: 3px 0;
          cursor: pointer;

          &.selected {
            border: 1px solid rgb(228, 225, 225);
            text-align: center;
            background-color: rgb(0, 204, 61);
            color: rgb(42, 42, 42);
          }
        }

        @media screen and (max-width: $medium) {
          flex-direction: row;
          flex-wrap: wrap;
          margin-top: 0rem;

          .answer {
            margin: 3px 2px;
            width: 48%;
          }
        }
      }
    }
  }
}